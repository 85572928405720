import { useCallback, useEffect, useMemo, useState } from "react";
import { ModalInventoryCaseSell } from "./modal-inventory-case-sell";
import { useAuthStore, useMarketplaceStore } from "src/stores";
import { IDashboardProfile } from "src/types/dashboard";
import {
  DISPLAY_MODE_CASE,
  DISPLAY_MODE_SPIN,
  DISPLAY_MODE_MAX_CASE,
  DISPLAY_MODE_GDD_CASE,
} from "src/config/dashboard";
import { useAuth } from "./auth-context";
import { ModalInventoryCaseSellAll } from "./modal-inventory-case-sell-all";
import { ISellItem } from "src/types/mysteryBox";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { InventorySwitch } from "./dashboard/inventory-switch";
import { InventorySpin } from "./dashboard/inventory-spin";
import { InventoryCase } from "./dashboard/inventory-case";

interface iDashboardInventory {
  data: IDashboardProfile;
  dashboardDisplayMode: string;
  setDashboardDisplayMode: (val: string) => void;
  getDashboardProfileCb: () => void;
  getDashboardTransactionsCb: () => void;
}

export const DashboardInventory = (props: iDashboardInventory) => {
  const {
    data,
    dashboardDisplayMode,
    setDashboardDisplayMode,
    getDashboardTransactionsCb,
  } = props;

  const [spins, setSpins] = useState<[] | null>(null);
  const [weapons, setWeapons] = useState<ISellItem[] | null>(null);
  const [openInventoryCaseSellModal, setOpenInventoryCaseSellModal] =
    useState(false);
  const [openInventoryCaseSellAllModal, setOpenInventoryCaseSellAllModal] =
    useState(false);
  const [inventoryCaseSelectedItems, setInventoryCaseSelectedItems] = useState<
    any[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { loggedIn, getDashboardProfileCb } = useAuth();
  const { getMyBag, getMyCaseBag, getMyCaseBagByCurrency } = useAuthStore();
  const { sellItemsById: sellWeapons } = useMarketplaceStore();

  const getMyBagCb = useCallback(() => {
    getMyBag().then((list) => {
      if (!list) return;
      setSpins(list);
    });
  }, [getMyBag]);

  const getMyCaseBagCb = useCallback(() => {
    getMyCaseBag().then((list) => {
      if (!list) return;
      setWeapons(list);
    });
  }, [getMyCaseBag]);

  const getMyCaseBagByCurrencyCb = useCallback(
    (currency?: string) => {
      getMyCaseBagByCurrency(currency).then((list) => {
        if (!list) return;
        setWeapons(list);
      });
    },
    [getMyCaseBagByCurrency]
  );

  const handleCancelInventoryCaseSellModal = () => {
    setInventoryCaseSelectedItems([]);
    setOpenInventoryCaseSellModal(false);
  };

  const handleCancelInventoryCaseSellAllModal = () => {
    setInventoryCaseSelectedItems([]);
    setOpenInventoryCaseSellAllModal(false);
  };

  const handleCompleteInventoryCaseSellModal = () => {
    setInventoryCaseSelectedItems([]);

    setWeapons(null);
    getMyCaseBagCb();

    getDashboardProfileCb();
    getDashboardTransactionsCb();

    setOpenInventoryCaseSellModal(false);
  };

  const handleCompleteInventoryCaseSellAllModal = () => {
    setInventoryCaseSelectedItems([]);

    setWeapons(null);
    DISPLAY_MODE_MAX_CASE === dashboardDisplayMode
      ? getMyCaseBagByCurrencyCb("MAX")
      : getMyCaseBagCb();

    getDashboardProfileCb();
    getDashboardTransactionsCb();

    setOpenInventoryCaseSellAllModal(false);
  };

  const handleSellAllWeapons = async () => {
    if (loading) return;
    if (sellItems.length === 0) {
      return openWarningNotification("No items to sell");
    }

    if (loading) return;
    setLoading(true);

    let formattedSellItems = sellItems.map((sellItem: any) => {
      return sellItem?._id;
    });

    let failedBatches = 0;

    // Process all batches, tracking failures
    while (formattedSellItems.length > 0) {
      const currentBatch = formattedSellItems.slice(0, 100);
      const result = await sellWeapons({
        ids: currentBatch,
      });

      if (!result || typeof result === "string") {
        failedBatches++;
        openWarningNotification(
          `Failed to sell batch of ${currentBatch.length} items`
        );
      }

      formattedSellItems = formattedSellItems.slice(100);
    }

    setLoading(false);

    if (failedBatches > 0) {
      openWarningNotification(
        `Completed with ${failedBatches} failed batches. Please check your transaction history.`
      );
    } else {
      openSuccessNotification(
        "Sell complete, the transfer may take some time."
      );
    }

    const isMaxCase = dashboardDisplayMode === DISPLAY_MODE_MAX_CASE;
    const isGddCase = dashboardDisplayMode === DISPLAY_MODE_GDD_CASE;
    const isGStarCase = dashboardDisplayMode === DISPLAY_MODE_CASE;

    if (isMaxCase) {
      getMyCaseBagByCurrencyCb("MAX");
    }
    if (isGddCase) {
      getMyCaseBagByCurrencyCb("GDD");
    }
    if (isGStarCase) {
      getMyCaseBagCb();
    }

    getDashboardProfileCb();
  };

  useEffect(() => {
    if (!loggedIn) {
      setSpins([]);
      setWeapons([]);
      return;
    }

    if (dashboardDisplayMode === DISPLAY_MODE_CASE) {
      setTimeout(() => {
        getMyCaseBagCb();
      }, 1000);
    }
    if (dashboardDisplayMode === DISPLAY_MODE_SPIN) {
      setTimeout(() => {
        getMyBagCb();
      }, 1000);
    }
    if (dashboardDisplayMode === DISPLAY_MODE_MAX_CASE) {
      setTimeout(() => {
        getMyCaseBagByCurrencyCb("MAX");
      }, 1000);
    }
    if (dashboardDisplayMode === DISPLAY_MODE_GDD_CASE) {
      setTimeout(() => {
        getMyCaseBagByCurrencyCb("GDD");
      }, 1000);
    }
  }, [
    dashboardDisplayMode,
    loggedIn,
    getMyBagCb,
    getMyCaseBagCb,
    getMyCaseBagByCurrencyCb,
  ]);

  const sellItems = useMemo(() => {
    if (!weapons || weapons.length === 0) return [];
    return weapons?.filter(
      (weapon: any) =>
        weapon?.item &&
        !weapon?.claimed &&
        !weapon?.sold &&
        !weapon?.is_favorite
    );
  }, [weapons]);

  const totalWeaponPrice = useMemo(() => {
    return sellItems
      ?.filter((weapon: any) => !weapon?.is_favorite)
      .reduce((total, weapon: any) => {
        return (
          total +
          (weapon?.item?.weaponConvertPrice || weapon?.item?.weaponPrice || 0)
        );
      }, 0);
  }, [sellItems]);

  return (
    <div>
      <div className="relative bg-[#19191966] p-0 sm:p-space-4 rounded-s backdrop-blur-[8px]">
        <p className="hidden sm:block text-l sm:text-[24px] leading-[19px] sm:leading-[28px] font-[500] blender-medium text-grey-100 mb-space-4">
          Inventory
        </p>
        <InventorySwitch
          dashboardDisplayMode={dashboardDisplayMode}
          setDashboardDisplayMode={setDashboardDisplayMode}
          setSpins={setSpins}
          setWeapons={setWeapons}
        />

        <div className="p-space-2 sm:p-space-4">
          {/* Spin inventory */}
          <InventorySpin
            dashboardDisplayMode={dashboardDisplayMode}
            spins={spins}
            loggedIn={loggedIn}
            getMyBagCb={getMyBagCb}
          />
          {/* Case inventory */}
          <InventoryCase
            dashboardDisplayMode={dashboardDisplayMode}
            weapons={weapons}
            loggedIn={loggedIn}
            loading={loading}
            totalWeaponPrice={totalWeaponPrice}
            handleSellAllWeapons={handleSellAllWeapons}
            getMyCaseBagCb={getMyCaseBagCb}
            getMyCaseBagByCurrencyCb={getMyCaseBagByCurrencyCb}
          />
        </div>
      </div>

      {/* Modal */}
      {openInventoryCaseSellModal && (
        <ModalInventoryCaseSell
          onCancel={() => handleCancelInventoryCaseSellModal()}
          onComplete={() => handleCompleteInventoryCaseSellModal()}
          dashboardProfile={data}
          sellItems={inventoryCaseSelectedItems}
        />
      )}
      {openInventoryCaseSellAllModal && (
        <ModalInventoryCaseSellAll
          onCancel={() => handleCancelInventoryCaseSellAllModal()}
          onComplete={() => handleCompleteInventoryCaseSellAllModal()}
          dashboardProfile={data}
          sellItems={sellItems || []}
        />
      )}
    </div>
  );
};
