import { useRef, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { getRarityColor, getPropertyColor } from "src/utils/color";
import { formatName, formatPrice } from "src/utils";
import { history } from "src/stores";
import { BsArrowRepeat, BsBoxArrowUp } from "react-icons/bs";
import { CaseOpeningModal } from "src/components/case-opening-modal";
import { calculateWeaponPrice } from "src/utils/case";
import { getCurrencyIcon } from "src/utils/image";
interface ICaseOpeningBoxSingleResultModal {
  className?: string;
  prizes: IMysteryBoxPrizeDetail[];
  isVisible: boolean;
  isValuable: boolean[];
  isSelling: boolean;
  bonus: number;
  onClose: () => void;
  onRetry: () => void;
  onSell: (type: "sell-selected" | "sell-all", index?: number) => void;
}

const CaseOpeningBoxSingleResultModal = ({
  className,
  prizes,
  isVisible,
  isValuable,
  bonus,
  onClose,
  onRetry,
  onSell,
}: ICaseOpeningBoxSingleResultModal) => {
  const prize = prizes[0];
  const {
    name = "",
    quality = "",
    property = "",
    imgUrl = "",
    type = "",
    sellCurrency,
  } = prize || {};
  const rarityColor = getRarityColor(quality);
  const propertyColor = getPropertyColor(property);
  const calculatedPrice = formatPrice(calculateWeaponPrice(prize));

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isValuableWeapon = isValuable[0];

  useEffect(() => {
    const video = videoRef.current;

    if (video && isValuableWeapon) {
      video.play();
      video.autoplay = true;
      video.loop = true;
    }

    return () => {
      if (video) {
        video.pause();
      }
    };
  }, [videoRef, isValuableWeapon]);

  const handleGoToInventory = () => {
    const currency = sellCurrency || "USDT";
    if (currency === "GDD") {
      return history.push("/dashboard#gdd-case");
    }

    if (currency === "MAX") {
      return history.push("/dashboard#max-case");
    }

    return history.push("/dashboard#case");
  };

  return (
    <CaseOpeningModal
      className={className}
      isVisible={isVisible}
      title={<span className="mt-space-2 block">Your Drop</span>}
      bonus={bonus}
      content={
        <div className="relative w-full h-[300px] md:h-[300px] flex flex-col justify-center items-center">
          <div
            className={clsx(
              "absolute items-center justify-center overflow-clip rounded-full border",
              "w-[180px] h-[180px] md:w-[240px] md:h-[240px]",
              { flex: isValuableWeapon, hidden: !isValuableWeapon }
            )}
            style={{ borderColor: rarityColor }}
          >
            {isValuableWeapon && (
              <video
                className="w-[180px] h-[180px] md:w-[240px] md:h-[240px] max-w-none"
                ref={videoRef}
                muted
              >
                <source
                  src="/assets/v4/legendary-square-frame.mp4"
                  type="video/mp4"
                />
              </video>
            )}
          </div>
          <div className="absolute top-2 flex justify-between items-center blender-medium px-space-6 w-full">
            <div className="block md:hidden items-center text-grey-800 leading-none">
              <div className="text-xs">All recent drops:</div>
              <div className="text-[8px]">
                Last updated {moment(new Date()).format("DD/MM/YY HH:mm:ss")}
              </div>
            </div>
            <button
              className={clsx(
                "md:hidden",
                "w-[90px] h-[24px] flex items-center justify-center gap-1",
                "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-xs cursor-pointer normal-case font-normal p-0"
              )}
              onClick={handleGoToInventory}
            >
              My inventory
              <img
                className="w-[10px] h-[10px]"
                src="/assets/v4/dashboard.png"
                alt=""
              />
            </button>
          </div>
          <img
            className="absolute w-[90%] md:w-auto md:h-full"
            src={imgUrl}
            alt={name}
          />
        </div>
      }
      footerClassName="pt-space-2 mx-space-4 md:mx-[25px] px-space-2"
      footer={
        <div className="-translate-y-[82px] md:translate-y-0 w-full grid md:flex md:items-stretch md:max-h-[138px] md:gap-space-4">
          {/* Left weapon property area */}
          <div className="basis-1/2 shrink overflow-y-auto max-h-[138px] scrollbar-hide">
            <div className="mb-space-2 text-l text-grey-100 leading-none">
              {formatName(name)}
            </div>
            <div className="grid grid-cols-3 grid-rows-[14px_14px_14px] md:grid-rows-[14px_14px_14px_24px] gap-space-1 text-s text-grey-800 leading-none">
              <span className="block col-span-2">Weapon Class:</span>
              <span className="col-span-1 text-grey-200 justify-self-end flex items-center">
                {formatName(type)}
              </span>
              <span className="block col-span-2">Rarity:</span>
              <div className="col-span-1 text-grey-200 justify-self-end flex items-center">
                <div
                  className="rounded-full w-[8px] h-[8px] mr-1"
                  style={{ backgroundColor: rarityColor }}
                />
                <p className="text-s text-gray-200">{formatName(quality)}</p>
              </div>
              <span className="block col-span-2">Wear rating:</span>
              <span
                className="col-span-1 justify-self-end flex items-center uppercase"
                style={{ color: propertyColor }}
              >
                {property}
              </span>
              <div className="hidden md:block self-center col-span-2">
                <span className="block">All recent drops:</span>
                <span className="block">
                  Last updated {moment(new Date()).format("DD/MM/YY HH:mm:ss")}
                </span>
              </div>
              <button
                className={clsx(
                  "hidden md:flex col-span-1",
                  "w-full h-full items-center justify-center gap-1",
                  "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-xs cursor-pointer normal-case font-normal p-0"
                )}
                onClick={handleGoToInventory}
              >
                My inventory
                <img
                  className="w-[10px] h-[10px]"
                  src="/assets/v4/dashboard.png"
                  alt=""
                />
              </button>
            </div>
          </div>
          {/* Dividing */}
          <div className="hidden md:flex basis-[1px] shrink-0 case-opening-dividing-line" />
          {/* Right buttons area */}
          <div className="md:basis-1/2 md:shrink grid grid-cols-2 grid-rows-[16px_28px_28px] md:gap-space-2 gap-1 mt-1 md:mt-0 pt-1 md:pt-0">
            <h5 className="text-l text-grey-100 leading-none">Sell all:</h5>
            <div className="flex items-center justify-end">
              <div className="text-native-green text-l leading-none">+</div>
              <img
                src={getCurrencyIcon(sellCurrency || "USDT")}
                className="w-[14px] h-[14px] mr-space-1"
                alt=""
              />
              <div className="text-native-green text-l leading-none">
                {calculatedPrice}
              </div>
            </div>
            <button
              className={clsx(
                "case-opening-sell-btn-green",
                "col-span-full",
                "w-full h-full flex items-center justify-center gap-space-2",
                "border rounded-xs border-native-green text-native-green text-l cursor-pointer normal-case font-normal"
              )}
              onClick={() => onSell("sell-all")}
            >
              <BsBoxArrowUp className="text-[12px]" />
              Sell
            </button>
            <button
              className={clsx(
                "group/retry w-full h-full flex items-center justify-center gap-space-1",
                "col-span-full",
                "text-orange-800 text-l cursor-pointer border-none normal-case font-normal"
              )}
              onClick={onRetry}
            >
              <BsArrowRepeat className="text-orange-800 transition-transform duration-300 group-hover/retry:rotate-180" />
              Try Again
            </button>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

export { CaseOpeningBoxSingleResultModal };
